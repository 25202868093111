<script lang='ts'>
  import IconSmiley from "@/components/icons/phosphor/IconSmiley.svelte";

  export let img = '',
    avatarVariant = 'smallAvatar',
    userName = ''
    let defaultAvatarColor = '#'
    let hash = 0;

    let size = 24
      switch (avatarVariant) {
        case 'menuAvatar':
          size = 40
          break
        case 'smallAvatar':
          size = 24
          break
        case 'bigAvatar':
          size = 60
          break
        case 'profileAvatar':
          size = 128
          break
        default:
          size = 24
    }

    for (let i = 0; i < userName.length; i++) {
      hash = userName.charCodeAt(i) + ((hash << 5) - hash);
    }

    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xFF
      defaultAvatarColor += ('00' + value.toString(16)).substr(-2)
    }
</script>

{#if img}
  <img class={`user-avatar -${avatarVariant}`} alt='' src={img} />
{:else}
  <div class={`user-avatar -${avatarVariant}`} style='color: {defaultAvatarColor}'>
    {#key size}
      <IconSmiley size={`${size}`} weight='duotone' />
    {/key}
  </div>
{/if}

<style lang='scss'>
  .user-avatar {
    object-fit: cover;
    border-radius: 50%;

    &.-menuAvatar {
      width: 4rem;
      height: 4rem;
      border-width: 0;
    }

    &.-smallAvatar {
      width: 2.4rem;
      height: 2.4rem;
    }

    &.-bigAvatar {
      width: 6rem;
      height: 6rem;
    }

    &.-profileAvatar {
      width: 12.8rem;
      height: 12.8rem;
    }
  }

  @media (max-width: 768px) {
    .user-avatar {
      &.-bigAvatar {
        width: 6rem;
        height: 6rem;
      }
    }
  }
</style>
