<script lang='ts'>
  import { allCountries } from '@/config/allCountries'

  import { calculateYearsOld, getLocalTime, timeAgo, toDate } from '@/helpers/dateHelpers'
  import { _ } from '@/helpers/i18n'

  import IconClockCounterClockwise from "@/components/icons/phosphor/IconClockCounterClockwise.svelte";
  import IconMapPin from "@/components/icons/phosphor/IconMapPin.svelte";
  import Avatar from '@/components/social/Avatar.svelte'

  import { UserProfile } from '@/definitions/langoid'

  export let user: UserProfile

  let registered: {
    day: number,
    month: string,
    year: number
  }
  let lastActivity: string | undefined
  const age = user.birthdate ? calculateYearsOld(user.birthdate) + ',' : ''

  $: if (user) {
    registered = toDate(user.created_at)
    lastActivity = timeAgo(user.updated_at, false)
  }
</script>

<div class='profile-box'>
  <div class='main-profile-info'>
    <Avatar avatarVariant='profileAvatar' img={user.avatar} userName={user.name} />
    <div class='mainUserInfo'>
      {#if user.name}
        <div class='fullName'>{user.name + ', ' + age}</div>
      {/if}
      <div class='locationTime'>
        {#if user.country}
          <div class='userBasicInfo'>
            <IconMapPin size='16' />
            {allCountries.find(item => item.id === user.country)?.name || ''},
          </div>
        {/if}
        {#if user.timezone}
          <div class='userBasicInfo'>{getLocalTime(user.timezone)}</div>
        {/if}
      </div>
    </div>
    <div class='level-and-points'>
      {#if user.level}
        <p class='level'>
          <b>{user.level}</b>
          <small>{$_('mix.level')}</small>
        </p>
      {/if}
      {#if user.points}
        <p class='points'>
          <b>{user.points}</b>
          <small>{$_('mix.points')}</small>
        </p>
      {/if}
    </div>
    {#if lastActivity}
      <div class='userBasicInfo lastActivity'>
        <IconClockCounterClockwise size='15' weight='regular' />
        <span>{$_('profile.lastActivity')} {lastActivity}</span>
      </div>
    {/if}
    {#if user.bio}
      <p class='aboutUser'>{user.bio}</p>
    {/if}
    {#if registered.month && registered.year}
      <span class='memberSince'>{$_('profile.memberSince')} {registered.month} {registered.year}</span>
    {/if}
  </div>
  <div />
</div>
<style lang='scss'>
  .main-profile-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--main-background);

    > :global(.-userAvatar) {
      margin: 0 !important;
    }

    > .mainUserInfo {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.8rem;

      > .locationTime {
        display: flex;
        margin-left: 0.8rem;

        > .userBasicInfo {
          display: flex;
          gap: 0.4rem;
          align-items: center;
          margin-right: 0.6rem;
          font-size: 1.2rem;
          color: var(--text-primary-color);
        }
      }

      > .fullName {
        font-size: 2rem;
      }
    }

    > .lastActivity {
      display: flex;
      gap: 0.4rem;
      align-items: center;
      justify-content: center;
      font-size: 1.2rem;
    }

    > .memberSince {
      font-size: 1.2rem;
      color: var(--text-primary-color);
    }
  }

  .level-and-points {
    display: flex;
    gap: 0.8rem;
    margin-bottom: 0.8rem;
  }
</style>
